@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  iframe {
    max-width: 100%;
  }

  :root {
    --max-width: 1100px;
    --border-radius: 12px;

    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;

    --primary-glow: conic-gradient(
      from 180deg at 50% 50%,
      #16abff33 0deg,
      #0885ff33 55deg,
      #54d6ff33 120deg,
      #0071ff33 160deg,
      transparent 360deg
    );
    --secondary-glow: radial-gradient(
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );

    --tile-start-rgb: 239, 245, 249;
    --tile-end-rgb: 228, 232, 233;
    --tile-border: conic-gradient(
      #00000080,
      #00000040,
      #00000030,
      #00000020,
      #00000010,
      #00000010,
      #00000080
    );

    --callout-rgb: 238, 240, 241;
    --callout-border-rgb: 172, 175, 176;
    --card-rgb: 180, 185, 188;
    --card-border-rgb: 131, 134, 135;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --foreground-rgb: 255, 255, 255;
      --background-start-rgb: 0, 0, 0;
      --background-end-rgb: 0, 0, 0;

      --primary-glow: radial-gradient(
        rgba(1, 65, 255, 0.4),
        rgba(1, 65, 255, 0)
      );
      --secondary-glow: linear-gradient(
        to bottom right,
        rgba(1, 65, 255, 0),
        rgba(1, 65, 255, 0),
        rgba(1, 65, 255, 0.3)
      );

      --tile-start-rgb: 2, 13, 46;
      --tile-end-rgb: 2, 5, 19;
      --tile-border: conic-gradient(
        #ffffff80,
        #ffffff40,
        #ffffff30,
        #ffffff20,
        #ffffff10,
        #ffffff10,
        #ffffff80
      );

      --callout-rgb: 20, 20, 20;
      --callout-border-rgb: 108, 108, 108;
      --card-rgb: 100, 100, 100;
      --card-border-rgb: 200, 200, 200;
    }
  }

  * {
    @apply p-0 m-0;
  }

  html,
  body {
    @apply max-w-[100vw] overflow-x-hidden scroll-smooth;
  }

  @media (prefers-color-scheme: dark) {
    html {
      color-scheme: dark;
    }
  }

  body {
    @apply bg-white font-roboto font-light text-pn-black-base text-base relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  :target {
    scroll-margin-top: 12rem;
  }

  ::placeholder {
    @apply text-base font-semibold text-pn-placeholder-base;
  }

  a {
    @apply text-inherit no-underline transition duration-150 ease-out;
  }

  h1,
  .h1 {
    @apply !text-h1 font-roboto-serif font-semibold leading-snug;
  }

  h2,
  .h2 {
    @apply text-h2 font-roboto-serif font-semibold leading-snug;
  }

  h3,
  .h3 {
    @apply text-h3 font-roboto-serif font-light leading-snug;
  }

  .content-flow > * + *:not(.btn-group, [class*="mt"]) {
    @apply mt-[1.25em];
  }
}

@layer utilities {
  .wrapper {
    @apply w-[90%] max-w-[1200px] mr-auto ml-auto;
  }

  .wrapper-sm {
    @apply !w-[75%] max-w-[1200px] mr-auto ml-auto;
  }

  .translate-center {
    transform: translate(-50%, -50%);
  }

  .decoration-skip-none {
    text-decoration-skip: none;
  }

  .image-scale {
    backface-visibility: hidden;
    transform: scale(110%) rotate(0.02deg);
  }

  .border-top {
    @apply border-t-[0.5px] border-solid border-pn-black-base;
  }

  .border-bottom {
    @apply border-b-[0.5px] border-solid border-pn-black-base;
  }

  .xs-ratio {
    aspect-ratio: 1 / 0.33;
  }
  .sm-ratio {
    aspect-ratio: 1 / 0.45;
  }
  .wide-ratio {
    aspect-ratio: 16 / 9;
  }
  .md-ratio {
    aspect-ratio: 3 / 2;
  }
  .lg-ratio {
    aspect-ratio: 4 / 3;
  }
  .sq-ratio {
    aspect-ratio: 1 / 1;
  }
  .xl-ratio {
    aspect-ratio: 1 / 1.12;
  }

  .btn {
    @apply gap-x-2.5 text-base select-none font-semibold text-center py-4 max-sm:px-5 px-[30px]
    leading-none font-roboto inline-flex items-center justify-center rounded-btn
    transition-all duration-400 ease-in-out max-h-[50px];
  }

  .btn-primary {
    @apply bg-pn-black-base hover:bg-pn-black-hover text-white;
  }

  .btn-secondary {
    @apply bg-pn-red-base border border-solid border-pn-black-base hover:bg-pn-red-hover text-white;
  }

  .btn-tertiary {
    @apply bg-white border border-solid border-pn-black-base hover:bg-pn-off-white-base text-pn-black-base;
  }

  .btn-quaternary {
    @apply bg-pn-red-base border border-solid border-pn-black-base hover:bg-pn-red-hover text-white;
  }

  .podcast-btn {
    @apply bg-white border border-solid border-pn-black-base py-5 h-auto min-[319px]:h-12 max-md:basis-full max-md:px-10 lg:px-[60px];
  }

  .h1-mobile-light {
    /* H1 Mobile - Light */
    leading-trim: both;
    text-edge: cap;
    @apply font-roboto-serif overflow-ellipsis font-light;
    font-size: 20px;
    line-height: normal;
  }

  .h1-bold {
    /* H1 - Bold */
    leading-trim: both;
    text-edge: cap;
    @apply font-roboto-serif overflow-hidden font-semibold overflow-ellipsis;
    font-size: 1.75rem;
    line-height: normal;
  }

  .h1-light {
    /* H1 - Light */
    leading-trim: both;
    text-edge: cap;
    @apply font-roboto-serif font-light overflow-ellipsis;
    font-size: 1.75rem;
    line-height: normal;
  }

  .h3-base {
    /* H3 */
    leading-trim: both;
    text-edge: cap;
    @apply font-roboto-serif font-light text-base;
    line-height: 1.375rem; /* 137.5% */
  }

  .h2-base {
    @apply font-roboto-serif overflow-hidden text-ellipsis font-semibold;
    leading-trim: both;
    text-edge: cap;
    font-size: 1.75rem;
    line-height: normal;
  }

  .paragraph-bold {
    /* Paragraph - Bold */
    leading-trim: both;
    text-edge: cap;
    @apply font-roboto text-base font-semibold;
    line-height: 1.375rem; /* 137.5% */
  }

  .paragraph-link {
    /* Paragraph - Link */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    leading-trim: both;
    text-edge: cap;
    @apply font-roboto overflow-hidden text-ellipsis text-base font-semibold underline;
    line-height: 1.375rem;
  }

  .paragraph-higher-leading {
    @apply leading-[1.625rem] font-roboto text-[1rem] overflow-hidden text-ellipsis font-normal;
    leading-trim: both;
    text-edge: cap;
  }

  .paragraph {
    /* Paragraph */
    @apply font-roboto text-[1rem] leading-[1.375rem] text-ellipsis font-normal overflow-hidden;
    leading-trim: both;
    text-edge: cap;
  }

  .paragraph-light {
    /* Paragraph - Light */
    @apply font-roboto text-[1rem] leading-[1.375rem] font-light uppercase;
    leading-trim: both;
    text-edge: cap;
  }

  .modal-shadow {
    box-shadow: 10px 30px 40px 0 rgba(24, 24, 24, 0.15);
  }

  .small-print-link {
    @apply font-roboto overflow-hidden text-ellipsis text-sm font-semibold underline;
    leading-trim: both;
    text-edge: cap;
  }

  .small-print {
    /* Small Print */
    @apply font-roboto overflow-hidden text-sm font-normal text-ellipsis;
    leading-trim: both;
    text-edge: cap;
  }

  .small-print-bold {
    /* Small Bold */
    @apply font-roboto overflow-hidden overflow-ellipsis text-sm font-semibold;
    leading-trim: both;
    text-edge: cap;
  }
}

/* Disable wrapper widths on single CPT pages
- These pages are contained within an outer wrapper already...
*/
.single-editorial main .acf-layout > .wrapper {
  width: 100% !important;
}

h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
  @apply block;
}

:hover {
  @apply transition duration-150 ease-linear;
}

#menu-header li + li {
  @apply border-l border-solid border-pn-black-base;
}

#menu-top-bar-menu li + li a {
  @apply border-l border-solid border-pn-black-base;
}

#search-form:hover {
  @apply bg-white transition ease-in-expo duration-400;
}

.site-logo {
  transition: ease 0.4s;
}

.site-logo:hover img {
  opacity: 0.7;
  transition: ease 0.4s;
}

main {
  @apply pt-8;
}

main,
aside {
  @apply md:pt-10;
}

aside {
  @apply md:pb-16;
}

.view-more-link {
  @apply font-roboto-serif font-light leading-[22px] flex
  items-center underline underline-offset-2 md:underline-offset-4;
}

div.post-meta:empty {
  display: none;
}

main > .acf-layout + .acf-layout {
  @apply mt-8;
}

.podcast-feed .full-description > p:last-of-type {
  display: inline-block;
}

b,
strong {
  font-weight: 600;
}

@media (min-width: 768px) {
  main > .acf-layout + .acf-layout {
    @apply mt-10;
  }
}

.fb-30 {
  flex: 1 0 30% !important;
}

.fb-35 {
  flex: 1 0 35% !important;
}

.fb-40 {
  flex: 1 0 40% !important;
}

.fb-45 {
  flex: 1 0 45% !important;
}

.fb-50 {
  flex: 1 0 50% !important;
}

.fb-55 {
  flex: 1 0 55% !important;
}

.fb-60 {
  flex: 1 0 60% !important;
}

.fb-65 {
  flex: 1 0 65% !important;
}

.fb-70 {
  flex: 1 0 70% !important;
}

.fb-75 {
  flex: 1 0 75% !important;
}

.keyword {
  @apply uppercase;
}

.padded-cards .series_keyword {
  @apply font-semibold font-roboto-serif text-h2;
}

#menu-header li:has(a[href*="/learning"].active),
body.post-type-learning #menu-header li:has(a[href*="/learning"]),
#menu-header li:has(a[href*="/learning"]):hover {
  @apply bg-pn-learning-base text-white;
}

/* .keyword.post-type-learning, */
/* .series_keyword.post-type-learning { */
/*   @apply text-pn-learning-base; */
/* } */

.card-title.post-type-learning a:hover {
  @apply underline text-pn-learning-base;
}

#menu-header li:has(a[href*="/news"].active),
body.post-type-news #menu-header li:has(a[href*="/news"]),
#menu-header li:has(a[href*="/news"]):hover {
  @apply bg-pn-news-base text-white;
}

/* .post-meta.news, */
/* .post-meta.issues { */
/*   @apply text-pn-news-base; */
/* } */

.bg-news {
  @apply !bg-pn-news-base border border-solid border-pn-black-base;
}

.bg-learning {
  @apply !bg-pn-learning-base border border-solid border-pn-black-base;
}

.bg-reviews {
  @apply !bg-pn-reviews-base border border-solid border-pn-black-base;
}

.bg-inspiration {
  @apply !bg-pn-inspiration-base border border-solid border-pn-black-base;
}

.card.news:hover .card-title {
  @apply text-pn-news-base;
}

.card.reviews:hover .card-title {
  @apply text-pn-reviews-base;
}

.card.inspiration:hover .card-title {
  @apply text-pn-inspiration-base;
}

.card.learning:hover .card-title {
  @apply text-pn-learning-base;
}

.card-title.post-type-news a:hover {
  @apply underline text-pn-news-base;
}

.__rcs-handle-button {
  @apply !border-0;
}

.__rcs-handle-button::after {
  content: "";
  background-image: url("/comparison-button.svg");
  @apply absolute inset-0 w-full h-full bg-cover bg-no-repeat;
}

.advanced-query-posts-block .card .post-meta {
  @apply mt-auto;
}

#menu-header li:has(a[href*="/reviews"].active),
body.post-type-reviews #menu-header li:has(a[href*="/reviews"]),
#menu-header li:has(a[href*="/reviews"]):hover {
  @apply bg-pn-reviews-base text-white;
}

.swiper-arrow {
  @apply rounded-btn flex items-center justify-center
  border border-solid border-pn-black-base bg-white bg-no-repeat bg-auto w-9 h-9
  hover:bg-pn-off-white-base cursor-pointer;
}

.swiper-arrow.swiper-prev::before {
  content: "";
  background-image: url('/icons/arrow_left.svg');
  width: 24px;
  height: 24px;
}

.swiper-arrow.swiper-next::before {
  content: "";
  background-image: url('/icons/arrow_right_alt.svg');
  width: 24px;
  height: 24px;
}


/* .post-meta.reviews { */
/*   @apply text-pn-reviews-base; */
/* } */

.card-title.post-type-reviews a:hover {
  @apply underline text-pn-reviews-base;
}

#menu-header li:has(a[href*="/inspiration"].active),
body.post-type-inspiration #menu-header li:has(a[href*="/inspiration"]),
#menu-header li:has(a[href*="/inspiration"]):hover {
  @apply bg-pn-inspiration-base text-white;
}

/* .post-meta.inspiration { */
/*   @apply text-pn-inspiration-base; */
/* } */

.card-title.post-type-inspiration a:hover {
  @apply underline text-pn-inspiration-base;
}

h3.trending::before {
  content: "";
  background-image: url("/icons/chart.svg");
  @apply bg-no-repeat bg-cover inline-block w-5 h-5 mr-1.5 align-middle;
}

.filter-menu li.active span.checkbox::before {
  content: "";
  background-image: url("/icons/check.svg");
  @apply bg-no-repeat bg-cover inline-block w-6 h-6 align-middle;
}

.single-post.series::before {
  content: "";
  background-image: url("/icons/series-icon.svg");
  backdrop-filter: contrast(0.96);
  @apply bg-no-repeat bg-cover inline-block w-6 h-6 align-middle absolute z-[4] top-0 left-0 opacity-100;
}

p.read-issue::after {
  content: "";
  background-image: url("/icons/exit.svg");
  @apply bg-no-repeat bg-cover inline-block w-6 h-6 align-middle relative ml-1;
}

/* WordPress CSS */
img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}
img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}
img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
a img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}
a img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}
a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.accordion::after {
  content: "";
  width: 12px;
  height: 7px;
  @apply block bg-no-repeat bg-auto bg-center absolute right-0 z-[5] self-end;
}

.accordion.inactive::after {
  background-image: url("/icons/accordion-arrow-down.svg");
}

.accordion.active::after {
  background-image: url("/icons/accordion-arrow-up.svg");
}

#compare-product::before {
  content: "";
  width: 20px;
  height: 18px;
  background-size: 20px 18px;
  background-image: url("/icons/add_a_photo.svg");
  @apply inline-block bg-no-repeat bg-center mr-2;
}

#compare-product::after {
  content: "";
  width: 9px;
  height: 5px;
  background-size: 9px 5px;
  background-image: url("/icons/accordion-arrow-down.svg");
  margin-left: 5px;
  @apply inline-block bg-no-repeat bg-center self-baseline;
}

.dynamic-posts-block.slider .h-cards .card-body,
.advanced-query-posts-block.slider .h-cards .card-body {
  @apply pb-0;
}

.content p a:not(.btn) {
  @apply underline font-semibold;
}

.confirmation-message.success::before {
  content: "";
  background-image: url("/icons/check_circle.svg");
  @apply mr-1.5 align-middle bg-cover bg-no-repeat inline-block w-6 h-6 shrink-0;
}

.featured-news-block .card {
  @apply h-full;
}

.featured-news-block .card-body {
  @apply grow;
}

.featured-news-block .post-meta {
  @apply mt-auto;
}

.yarl__counter {
  @apply font-roboto text-h2 tracking-tighter;
}

.swiper-button-disabled {
  opacity: 0.5 !important;
}

.image-lightbox::after {
  content: "";
  background-size: 20px 20px;
  background-image: url("/icons/expand.svg");
  bottom: 5px;
  right: 5px;
  @apply h-5 w-5 inline-block bg-no-repeat bg-center absolute z-[5];
}

/* .swiper-slide { */
/*   visibility: hidden; */
/* } */

.swiper-slide-active {
  visibility: visible;
}

@media (max-width: 800px) {
  .padded-cards .card {
    border: none;
    /* padding-bottom: 20px; */
  }

  /* .padded-cards .swiper .swiper-arrows { */
  /*   display: none !important; */
  /* } */

  .padded-cards .swiper {
    padding-bottom: 20px;
    border-bottom: 1px solid black;
  }

  .advanced-query-posts-block .card,
  .related-posts .card,
  .series-posts .card {
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 20px;
  }

  .advanced-query-posts-block .card .card-media,
  .related-posts .card .card-media,
  .series-posts .card .card-media {
    aspect-ratio: 1 / 1;
    max-width: 170px;
    min-width: 130px;
  }

  .advanced-query-posts-block .card .card-body,
  .related-posts .card .card-body,
  .series-posts .card .card-body {
    padding-bottom: 0 !important;
    flex-grow: 2;
  }

  .advanced-query-posts-block .card .media-link,
  .related-posts .card .media-link,
  .series-posts .card .media-link {
    aspect-ratio: 1/1;
    min-width: 130px;
    max-width: 170px;
  }

  .no-flexbox-gap .advanced-query-posts-block .card > * + *,
  .no-flexbox-gap .related-posts .card > * + *,
  .no-flexbox-gap .series-posts .card > * + * {
    @apply ml-5;
  }
}

@media (min-width: 800px) {
  .dynamic-posts-block .swiper-arrows {
    position: absolute;
    right: calc(45% - 40px - 75px);
    bottom: 0;
  }

  .dynamic-posts-block .h-cards .view-more-link {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .padded-cards .card {
    min-height: 395px;
  }

  .padded-cards .card img {
    border-radius: 0 !important;
  }

  .card.padding .card-body {
    @apply p-10 !important;
  }
}

@media (min-width: 800px) and (max-width: 1199px) {
  .padded-cards .swiper-arrows {
    bottom: 10px;
  }
}


@media (min-width: 1200px) {
  .padded-cards .swiper-arrows {
    bottom: 40px;
  }
}

.bright-audio-player .rhap_container {
  box-shadow: none !important;
  @apply !p-0;
}


/* Header animation CSS */
/* @media (min-width: 1024px) { */
/*   .nav-area { */
/*     transition: max-height 100ms linear; */
/*     max-height: 153px; */
/*   } */

/*   body.scrolled .nav-area { */
/*     max-height: 53px; */
/*   } */

/*   .top-bar { */
/*     max-height: 100px; */
/*     transition: max-height 100ms linear; */
/*   } */

/*   body.scrolled .top-bar { */
/*     max-height: 0; */
/*     height: 0; */
/*     opacity: 0; */
/*   } */

/*   .desktop-menu-bar { */
/*     top: 100px; */
/*     transition: */
/*       top 200ms linear, */
/*       background-color 200ms linear; */
/*   } */

/*   body.scrolled .desktop-menu-bar { */
/*     position: relative; */
/*     top: 0; */
/*   } */
/* } */

@supports not (aspect-ratio: 1/1) {
  .xs-ratio {
    padding-bottom: 33% !important;
  }
  .sm-ratio {
    padding-bottom: 45% !important;
  }
  .wide-ratio {
    padding-bottom: 56.9% !important;
  }
  .md-ratio {
    padding-bottom: 66.67% !important;
  }
  .lg-ratio {
    padding-bottom: 75% !important;
  }
  .sq-ratio {
    padding-bottom: 100% !important;
  }
  .xl-ratio {
    padding-bottom: 112% !important;
  }

  .aspect-square {
    padding-bottom: 100%;
  }

  @media (max-width: 767px) {
    .advanced-query-posts-block .card .media-link,
    .related-posts .card .media-link,
    .series-posts .card .media-link {
      padding-bottom: 100% !important;
    }
  }
}

/* negative margin on the container */
.no-flexbox-gap .gap-2\.5 {
  margin-left: -0.625rem;
}

.no-flexbox-gap .gap-2\.5 > * {
  @apply ml-2.5;
}

.no-flexbox-gap .gap-5 {
  margin-left: -1.25rem;
}

.no-flexbox-gap .gap-5 > .gap-5 {
  margin-left: 0;
}

.no-flexbox-gap .gap-5 > * {
  @apply ml-5 mb-5;
}

.no-flexbox-gap .card-body {
  @apply pt-5;
}

.no-flexbox-gap .card-body > * + * {
  @apply pt-5;
}

.arrow--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.wordpress-content ul {
  @apply list-disc list-inside;
}

main ul:not([class]) {
  @apply list-outside list-disc ml-5;
}

main ol {
  @apply list-outside list-decimal ml-5;
}
